import type { SerializeFrom } from "@remix-run/server-runtime";
import {
	Await,
	ClientActionFunctionArgs,
	ClientLoaderFunctionArgs,
	useLoaderData,
	useNavigate,
} from "@remix-run/react";
import { useEffect, useState } from "react";
import React from "react";

const map = new Map();

export interface CacheAdapter {
	getItem: (key: string) => any | Promise<any>;
	setItem: (key: string, value: any) => Promise<any> | any;
	removeItem: (key: string) => Promise<any> | any;
}

export let cache: CacheAdapter = {
	getItem: async (key) => map.get(key),
	setItem: async (key, val) => map.set(key, val),
	removeItem: async (key) => map.delete(key),
};

async function awaitObjectProperties<T = any>(object: {
	[key: string]: Promise<T> | any;
}): Promise<{ data: { [key: string]: any } | null; error: any | null }> {
	try {
		const keys = Object.keys(object);
		const values = Object.values(object);

		const resolvedValues = await Promise.all(
			values.map((value) =>
				value instanceof Promise ? value : Promise.resolve(value),
			),
		);

		const resolvedObject: { [key: string]: any } = {};

		keys.forEach((key, index) => {
			resolvedObject[key] = resolvedValues[index];
		});

		return { data: resolvedObject, error: null };
	} catch (error) {
		return { data: null, error: error };
	}
}

const augmentStorageAdapter = (storage: Storage) => {
	return {
		getItem: async (key: string) => {
			try {
				const item = JSON.parse(storage.getItem(key) || "");

				return item;
			} catch (e) {
				return storage.getItem(key);
			}
		},
		setItem: async (key: string, val: any) =>
			storage.setItem(key, JSON.stringify(val)),
		removeItem: async (key: string) => storage.removeItem(key),
	};
};

export const createCacheAdapter = (adapter: () => CacheAdapter) => {
	if (typeof document === "undefined") return { adapter: undefined };
	const adapterInstance = adapter();
	if (adapterInstance instanceof Storage) {
		return {
			adapter: augmentStorageAdapter(adapterInstance),
		};
	}
	return {
		adapter: adapter(),
	};
};

export const configureGlobalCache = (
	newCacheInstance: () => CacheAdapter | Storage,
) => {
	if (typeof document === "undefined") return;
	const newCache = newCacheInstance();
	if (newCache instanceof Storage) {
		cache = augmentStorageAdapter(newCache);
		return;
	}
	if (newCache) {
		cache = newCache;
	}
};

export const decacheClientLoader = async <T extends unknown>(
	{ request, serverAction }: ClientActionFunctionArgs,
	{
		key = constructKey(request),
		adapter = cache,
	}: { key?: string; adapter?: CacheAdapter },
) => {
	const data = await serverAction<T>();
	await adapter.removeItem(key);
	return data;
};

export const cacheClientLoader = async <T extends unknown>(
	{ request, serverLoader }: ClientLoaderFunctionArgs,
	{
		type = "swr",
		key = constructKey(request),
		adapter = cache,
	}: { type?: "swr" | "normal"; key?: string; adapter?: CacheAdapter } = {
		type: "swr",
		key: constructKey(request),
		adapter: cache,
	},
): Promise<
	SerializeFrom<T> & {
		serverData: SerializeFrom<T>;
		deferredServerData: Promise<SerializeFrom<T>> | undefined;
		key: string;
	}
> => {
	const existingData = await adapter.getItem(key);

	if (type === "normal" && existingData) {
		return {
			...existingData,
			serverData: existingData as SerializeFrom<T>,
			deferredServerData: undefined,
			key,
		};
	}
	const isFresh = await serverLoader();
	const data = existingData ? existingData : isFresh;
	// const { data } = await awaitObjectProperties(isData);

	await adapter.setItem(key, isFresh);
	const deferredServerData = existingData ? serverLoader() : undefined;
	return {
		...(data ?? existingData),
		serverData: data as SerializeFrom<T>,
		deferredServerData,
		key,
	};
};

/**
 * Remix client cache from forge42dev
 * https://github.com/forge42dev/remix-client-cache/blob/main/src/index.tsx
 *
 * Default not support data defer from loader
 * now support defer data
 * now support useFetcher
 */

export function useCachedLoaderData<T extends any>(
	{ adapter = cache }: { adapter?: CacheAdapter } = { adapter: cache },
) {
	const loaderData = useLoaderData<any>();
	const navigate = useNavigate();
	const [freshData, setFreshData] = useState<any>({
		...("serverData" in loaderData ? loaderData.serverData : loaderData),
	});

	// Unpack deferred data from the server
	useEffect(() => {
		let isMounted = true;
		if (loaderData.deferredServerData) {
			loaderData.deferredServerData
				.then(async (newData: any) => {
					if (isMounted) {
						const { data } = await awaitObjectProperties(newData);
						adapter.setItem(loaderData.key, data);
						setFreshData(data);
					}
				})
				.catch((e: any) => {
					const res = e instanceof Response ? e : undefined;
					if (res && res.status === 302) {
						const to = res.headers.get("Location");
						to && navigate(to);
					} else {
						throw e;
					}
				});
		}
		return () => {
			isMounted = false;
		};
	}, [loaderData]);

	// Update the cache if the data changes
	useEffect(() => {
		if (
			loaderData.serverData &&
			JSON.stringify(loaderData.serverData) !== JSON.stringify(freshData)
		) {
			setFreshData(loaderData.serverData);
		}
	}, [loaderData?.serverData]);

	return {
		...loaderData,
		...freshData,
		cacheKey: loaderData.key,
		invalidate: () => invalidateCache(loaderData.key),
	} as SerializeFrom<T> & {
		cacheKey?: string;
		invalidate: () => Promise<void>;
	};
}
import { useFetcher } from "@remix-run/react";
import { useMemo } from "react";

export function useCacheFetcher<T>({ key }: { key?: string }) {
	const isKey = key ? { key } : undefined;
	const originalFetcher = useFetcher<T>(isKey);
	const fakeMemoizedFetcher = useMemo(() => ({}) as typeof originalFetcher, []);
	const loaderData = originalFetcher.data as any;
	const adapter = cache;

	const navigate = useNavigate();
	const [freshData, setFreshData] = useState<any>({
		...(loaderData && "serverData" in loaderData
			? loaderData.serverData
			: loaderData),
	});

	// Unpack deferred data from the server
	useEffect(() => {
		let isMounted = true;
		if (loaderData?.deferredServerData) {
			loaderData?.deferredServerData
				.then(async (newData: any) => {
					if (isMounted) {
						const { data } = await awaitObjectProperties(newData);
						adapter.setItem(loaderData.key, data);
						setFreshData(data);
					}
				})
				.catch((e: any) => {
					const res = e instanceof Response ? e : undefined;
					if (res && res.status === 302) {
						const to = res.headers.get("Location");
						to && navigate(to);
					} else {
						throw e;
					}
				});
		}
		return () => {
			isMounted = false;
		};
	}, [loaderData]);

	// Update the cache if the data changes
	useEffect(() => {
		if (
			loaderData?.serverData &&
			JSON.stringify(loaderData.serverData) !== JSON.stringify(freshData)
		) {
			setFreshData(loaderData.serverData);
		}
	}, [loaderData?.serverData]);

	return Object.assign(fakeMemoizedFetcher, originalFetcher, {
		...originalFetcher,
		data: {
			...loaderData,
			...freshData,
			cacheKey: loaderData?.key,
			invalidate: () => invalidateCache(loaderData?.key),
		} as SerializeFrom<T> & {
			cacheKey?: string;
			invalidate: () => Promise<void>;
		},
	});
}

const constructKey = (request: Request) => {
	const url = new URL(request.url);
	return url.pathname + url.search + url.hash;
};

export const invalidateCache = async (key: string | string[]) => {
	const keys = Array.isArray(key) ? key : [key];
	for (const k of keys) {
		await cache.removeItem(k);
	}
};

export const useCacheInvalidator = () => ({
	invalidateCache,
});

export function useSwrData<T>({
	serverData,
	deferredServerData,
	...args
}: any) {
	return function SWR({
		children,
	}: {
		// Tentukan tipe data yang akan diterima oleh children
		children: (data: T) => React.ReactElement;
	}) {
		const _deferredServerData = deferredServerData
			? deferredServerData.then(async (newData: any) => {
					const { data } = await awaitObjectProperties(newData);
					return data as T; // Casting data sebagai T
				})
			: undefined;

		return (
			<>
				{_deferredServerData ? (
					<React.Suspense fallback={children(serverData as T)}>
						<Await resolve={_deferredServerData}>
							{(resolvedData: T) => children(resolvedData)}
						</Await>
					</React.Suspense>
				) : (
					children(serverData ?? (args as T))
				)}
			</>
		);
	};
}
